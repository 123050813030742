.password-wrapper {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.password-container {
    width: 300px;
    padding: 2em;
}

.password-container form {border: 3px solid #f1f1f1;}

.password-container input[type=text], input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.password-container button {
    background-color: #737373;
    color: white;
    font-family: JetBrains Mono;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
}

.password-container button:hover {
    opacity: 0.8;
}

span.psw {
    float: right;
    padding-top: 16px;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
    span.psw {
        display: block;
        float: none;
    }
    .cancelbtn {
        width: 100%;
    }
}