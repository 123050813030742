.timeline {
  background: #1e1e1e;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  /* margin: 60px; */
  width: 100%;
  display: flex;
  flex-direction: row;
}
.phasebox {
  background: #1e1e1e;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  height: 220px;
  position: inherit;
  min-width: 18%;
  display: flex;
  flex-direction: column;
  
}

.descriptionbox {
  background: #1e1e1e;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 82%;
}
.roadmap-text {
  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  /* line-height: 32px; */
  /* identical to box height */

  color: #ffffff;
  /* identical to box height */

  color: #fff;
}
.roadmap-description {
  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 1.75em;
  text-align: start;
  padding: 20px;
  color: #ffffff;
}
.phase {
  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  /* line-height: 32px; */
  /* identical to box height */
  text-align: left;
  padding: 15px;
  color: #ffffff;
}

.number {
  font-family: Virus_01;
  font-style: normal;
  font-weight: normal;
  font-size: 4rem;
  text-align: center;
  color: #ffffff;
  margin-block-start: 0;
  margin-block-end: 0;
}

@media (max-width: 768px) {
  .descriptionbox {
    background: #1e1e1e;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .roadmap-text {
    font-size: 24px;
  }
  .timeline {
    width: 80%;

    flex-direction: column;
    margin: 60px;
  }

  .phase {
    font-size: 1.5em;
  }

  .number {
    font-size: 64px;
  }
  .roadmap-description {
    font-size: 14px;
  }

}

@media (max-width: 480px) {
  .phasebox {
    margin: 0;
    padding: 0.5em 0 2em 0;
    height: 100%;
  }
  .descriptionbox {
    background: #1e1e1e;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 .5em;

  }
  .roadmap-text {
    font-size: 24px;
  }
  .timeline {
    width: 85%;
    height: 100%;
    flex-direction: column;
    margin: 0 auto;
  }

  .phase {
    font-size: 1.5em;
    padding: 0.0 0 0 1em;
    /* line-height: 0; */
  }

  .number {

    font-size: 64px;

  }
  .roadmap-description {

    font-size: 14px;

  }
  .phase-container {

  }
  .number-container {


  }

}
