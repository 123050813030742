.countdownTimer_container {
  background-color: transparent;
  padding: 0 0 2em;
  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 2em;
  text-transform: uppercase;
  color: #fff;
}

@media (max-width: 480px) {
  .countdownTimer_container {
    padding: 0 1em 2em;
  }
}

