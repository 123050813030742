.App {
  text-align: center;
  background-color: #1e1e1e;
  overflow-y: hidden;
  max-width: 100vw;
  min-height: 100vh;
}
img {
  width: 608px;
}
a {
  color: white;
}
a:hover {
  color: rgba(255, 255, 255, 0.507);
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
}
.container-wrap {
  width: 72%;
  overflow-x: hidden;

  justify-content: center;
  justify-items: center;
  margin: 0 auto;
}
.menu {
  display: flex;
}
.menu-item {
  font-family: pxlxxl;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 34px;
  /* identical to box height */
  margin: 0px 20px;
  color: #ffffff;
  scroll-behavior: smooth;

  color: #fff;
}

.heading-style {
  font-family: pxlxxl;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 45px;
  /* identical to box height */

  text-align: center;

  color: #fff;
}
.subheading {
  font-family: pxlxxl;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 34px;

  color: #fff;
}
.box {
  margin: 100px 0px;
}
.normal-text {
  font-family: JetBrains Mono;
  font-style: normal;

  /* line-height: 1.3em; */
  text-align: left;
  color: #fff;
}
.MuiMenu-list {
  background-color: #1e1e1e;
}
.MuiMenu-paper {
  background-color: #1e1e1e;
}
.MuiPopover-paper {
  background-color: transparent !important;
  border: 1px solid #fff;
}
.menu-mobile-svg {
  width: 40px;
  color: #fff;
}
.logo {
  width: 40px;
  height: 40px;
  padding: 40px 60px;
}

.subhead {
  font-family: pxlxxl;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 34px;

  color: #fff;
}
.roadmap {
  width: 100%;
  height: 757px;

  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  text-align: start;
}
.socialdiv {
}
.socialicon {
  width: 30px;
  height: 30px;
  padding: 20px;
}

.smallheading {
  font-family: pxlxxl;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 34px;
  text-align: center;

  color: #fff;
}

.text-container {
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
  text-align: start;
}

.text-container-first {
  width: 50%;
  overflow: hidden;
  text-align: start;
  padding: 60px;
  margin-top: 160px;
}
.containerformetaverse {
  width: 35%;
}
.imgformetaverse {
  width: 100%;
}

.container-viewall-item {
  width: 46%;
  justify-content: center;
  display: flex;
  align-items: center;
}
.small-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.img-view-all {
  object-fit: cover;
  width: 100%;
}
.list {
  padding: 10px;
}
.landing-head {
  font-family: Virus_01;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 61px;
  /* identical to box height */

  color: #ffffff;
}

.jointherank {
  font-family: Virus_01;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 17px;

  color: #151515;
}
.jointherankcontainer {
  width: 269px;
  height: 50px;

  margin: 0 auto;
  background: #ffffff;
}
.landing-front {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
}
.new-section-text {
  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 2.25em;
  line-height: 2em;
  text-align: left;
  color: #ffffff;
  margin-top: 1.5em;
}


input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}
.topmargin {
  width: 135px;
  height: 0px;
  margin-top: 60px;
  border: 1px solid #ffffff;
}

.divider {
  width: 100%;
  height: 0px;
  position: absolute;
  left: 0;
  right: 0;

  border: 1px solid #ffffff;
}
.navbar-socialicon {
  width: 30px;
  height: 30px;
  padding: 15px;
  padding-bottom: 0px;
}
.social {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.center {
  align-items: center;
}
.menu-mobile {
  display: none;
}
.terms-content > h2 {
  margin: 0;
  color: white;
}
.terms-content > p {
  margin: 30px 50px;
}
.terms-content > h4 {
  margin: 10px 50px;
  color: white;
  text-align: left;
}
.template-text {
  color: #fff;
  margin: 70px;
  text-align: justify;
}
.jointherankcontainer > a {
  text-decoration: none;
}
@media (max-width: 1300px) {
  .navbar-socialicon {
    width: 30px;
    height: 30px;
    padding: 6px;
  }
}
@media (max-width: 768px) {
  .footer > a {
    margin: 15px;
  }
  .logo {
    display: none;
  }
  .footer {
    margin: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .menu-mobile {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
  }
  .menu-mobile {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
  }
  .menu-mobile button {

  }
  .menu-mobile a {
  }
  .menu {
    display: none;
  }
  .navbar-socialicon {
    width: 25px;
    height: 25px;
    padding: 6px;
  }
  .topmargin {
    width: 135px;
    height: 0px;

    border: 0;
  }
  .small-cont {
    display: block;
  }
  .new-section-text {
    font-size: 2em;
    margin: 1em 1.5em;
    line-height: 1.5em;
  }
  .containerformetaverse {
    width: 100%;
  }

  .text-container-first {
    width: 90%;
    overflow: hidden;
    text-align: start;
    padding: 0px;
    /* margin-top: 60px; */
    margin: 0 1em;
  }


  
  .container-wrap {
    width: 100%;
  }
  .container-viewall-item {
    width: 100%;
    padding: 20px 0;
  }
  .divider {
    display: none;
  }
  .roadmap-description {
    font-size: 14px;
    padding: 5px;
  }
  .logo {
    padding: 40px;
  }
  .timebox {
    min-width: 80%;
  }


  .menu-item {
    padding: 15px;

    font-size: 30px;
  }
  .normal-text {
    font-size: 16px;
    padding: 10px;
  }
  .new-section-text {
    text-align: center;
  }
  .norm-padding {
    margin: 0 1rem;
  }
}

@media (max-width: 480px) {
  .menu-mobile {
    display: flex;
  }
  .footer {
    margin: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .footer > a {
    margin: 15px;
  }
  .logo {
    padding: 10px;
    height: 30px;
    width: 30px;
  }
  .menu-item {
    padding: 0px;
    font-size: 30px;
  }
  .navbar-socialicon {
    width: 20px;
    height: 20px;
    padding: 5px;
  }
}
#roadmap {
  scroll-behavior: smooth;
}
