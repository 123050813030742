body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  scroll-behavior: smooth;

  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "pxlxxl";
  src: local("pxlxxl"), url("./fonts/pxlxxl.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Virus_01";
  src: local("Virus_01"), url("./fonts/Virus_01.ttf") format("truetype");
  font-weight: normal;
}
