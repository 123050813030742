.accordion__heading {
  color: #fff;
  font-family: JetBrains Mono;
  font-weight: 600;
}


.container-faq {
  justify-content: center;
  align-items: center;
}

.accordion__button {
  background-color: #fff;
  color: #fff !important;
  width: 98% !important;
}

.faq-inner-container {
  border: 1px solid rgb(255, 255, 255);
  padding: 25px;
  box-sizing: border-box;
  display: block;
  justify-content: center;
  align-items: center;
}
.FAQ .separator {
  height: 4em;
}

@media (max-width: 768px) {
  .accordion__button {
    background-color: #fff;
    color: #fff !important;
    width: auto !important;
  }

  .faq-inner-container {
    border: none;
    padding: 2em 0em;
    box-sizing: border-box;
    display: block;
    justify-content: center;
    align-items: center;
  }
}
