.footer {
    margin: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .footer > div > a {
    color: #fff;
    text-decoration: none;
    font-family: JetBrains Mono;
  }

@media (max-width: 768px) {
    .footer > a {
        margin: 15px;
    }
    .logo {
        display: none;
    }
    .footer {
        margin: 50px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .footer {
      margin: 50px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
    }
    .footer > a {
      margin: 15px;
    }
}