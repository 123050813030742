.mint-page-btn:active {
  background-color: rgba(255, 255, 255, 0.13);
  border-color: rgba(255, 255, 255, 0.507);
}
.mint-page-btn:hover {
  cursor: pointer;
}
.mint-btn:hover {
  cursor: pointer;
}
.mint-btn:active {
    background-color: rgba(255, 255, 255, 0.13);
    border-color: rgba(255, 255, 255, 0.507);
    color: white;
}
.mint-form-input-btn {
  width: 35px;
  color: #fff;
}
.mint-form-input-cont {
  position: relative;
}
.mint-form-input-btns {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 28%;

  top: 0px;
}
.mint-btn {
  width: 50%;
  padding: 15px 30px;
  height: 80px;
  background-color: #fff;
  border: 1px solid #fff;
  font-family: Virus_01;
  font-size: 48px;
}
.Mint .new-section-text {
    margin: 0;
}
.mint-ul {
  color: #fff;
  text-align: left;
  font-family: JetBrains Mono;
  margin: 0 auto;
  padding: 1em 2em 3em;
}
.mint-ul > li{
    line-height: 1.75em;
}
.mint-form-info {
  color: #fff;
  text-align: center;
  font-family: JetBrains Mono;
  margin: 20px auto;
  width: 40%;
  padding: 15px 30px;
}
.mint-form-input {
  width: 40%;
  font-family: pxlxxl;
  position: relative;
  height: 80px;
  background-color: transparent;
  font-size: 65px;
  outline: none;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 400;
  padding: 15px 30px;
}
.mint-form {
  width: 90%;
  max-height: 500px;
  border: 2px solid #fff;
  margin: 2em auto;
  padding: 100px 0px;
}
.mint-page-btn {
  width: 90%;
  height: 100px;
  border: 2px solid #fff;
  background-color: transparent;

  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  text-transform: uppercase;
  line-height: 63px;
  color: #fff;
}
.mint-btn-container {
    
}
.footer {
  margin: 50px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer > div > a {
  color: #fff;
  text-decoration: none;
  font-family: JetBrains Mono;
}

@media (max-width: 768px) {
    
}

@media (max-width: 480px) {
    .Mint .new-section-text {
        padding-top: 1em;
    }
    .mint-ul > li{
        font-size: 0.9em;
    }
    .mint-btn {
        width: 50%;
        padding: 1em 1.25em;
        height: 3em;
        background-color: #fff;
        border: 1px solid #fff;
        font-family: Virus_01;
        font-size: 2em;
        text-align: center;
      }
}

