.landing-front-wrapper {
    padding: 0 0 1.5em;
}
.landing-front .normal-text {
    text-align: center;
    width: 100%;
    margin: 0;
}
.landing-front-text {
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 0em 0 3em;
}

.newhead {
    font-family: Virus_01;
    font-style: normal;
    font-weight: normal;
    font-size: 6.2em;
    line-height: 61px;  
    color: #ffffff;
    margin: 1em 0 1em;
    width: 100%;
}

@media (max-width: 1300px) {
    .newhead {
      font-family: Virus_01;
      font-style: normal;
      font-weight: normal;
      font-size: 4em;
      line-height: 61px;
      color: #ffffff;
    }
}
@media (max-width: 768px) {
    .newhead {
        font-size: 30px;
    }
}
  
@media (max-width: 480px) {
    .newhead {
      font-family: Virus_01;
      font-style: normal;
      font-weight: normal;
      font-size: 2em;
      line-height: 61px;
      color: #ffffff;
    }
}