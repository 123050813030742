.Home .new-section-text {
    margin: 2em 1em 1em;
}
/* .Home .container-wrap {
    width: 100%;
} */
.logo-banner {
    height: 40px;
    width: 40px;
    margin: 80px;
}
.logo-banner-wrapper {
    height: 200px;
}
.img-banner {
    object-fit: cover;
    width: 100%;
    height: 270px;
}
.twocol-container {
    display: flex;
    flex-direction: row;
}
.twocol-left {
    width: 33%;
}
.twocol-right {
    width: 67%;
}
.twocol-left > img {
    width: 85%;
    padding: 0 7.5%;
}
.twocol-right > p {
    margin: 0;
    padding: 0;
}
.Home .animetas{
    margin: 3em 0 0 0;
    padding: 2em 0 2em 0;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}
.Home .benefits {
    padding: 1em 0 1em 0;
    list-style-type: none;
}
.Home .onecol-container > .center ul {
    padding: 0 0.5em;
}
.Home .onecol-container > .center li {
    list-style-type: none;
    line-height: 1.25em;
    padding: 0.5em 0;
}
.Home .center {
    text-align: center;
    font-family: JetBrains Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;
    color: #ffffff;
    /* font-weight: 1000; */
}
.benefits-container {
    border: 2px solid rgb(255, 255, 255);
    border-radius: 25px;
    margin: 4.5em 3em 6em 3em;
    padding: 2em;
    background-image: url("../../assets/bg_fuzzy.png");
}

.benefits-container .new-section-text {
    margin: 0.5em 0 0.5em;
    text-align: center;
    font-weight: bold;
}
.invasion-target {
    font-weight: 1000;
}
.summary-container li{
    font-weight: bold;
    list-style-type: disclosure-closed;
}

.summary-container {
    border: 1px solid rgb(255, 255, 255);
    padding: 1em;
    box-sizing: border-box;
    margin-bottom: 1em;
}
.Home .separator {
    height: 6em;
}
.team-container {
    margin: 5em 0 2.5em 0;
}
.team-container .new-section-text {
    margin: 1em 1em 1em;
}

.Home .team-image-container {
    display: flex;
    flex-direction: row;
}
.Home .profile-container {
    width: 25%;
}
.Home .profile-image {
    width: 80%;
}
.Home .profile-name {
    font-family: JetBrains Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;
    color: #ffffff;
    font-weight: 1000;
}
.Home .profile-name span { 
    font-size: 0.8em;
    font-weight: 100;
}
@media (max-width: 1300px) {
    .twocol-container > div {
        width: 50%;
    }
}
@media (max-width: 768px) {

}

@media (max-width: 480px) {
    .Home .new-section-text {
        margin: 0.5em 1em;
    }
    .twocol-container {
        flex-direction: column;
    }
    .twocol-container > div {
        width: 100%;
    }
    .summary-wrapper {
        padding: 7.5%;
    }
    .twocol-right > p {
        padding: 2.5% 7.5% 0;
    }
    .Home .animetas{
        padding: 1em 0 1em 0em;
    }
    .invasion-text {
        padding: 0 1em;
    }
    .Home .benefits {
    }
    .Home .center {
        font-size: 1em;
    }
    .Home .onecol-container > .center ul  {
        font-size: 1em;
        padding: 0 1em;
    }
    .logo-banner {
        height: 40px;
        width: 40px;
        margin: 50px;
    }
    .logo-banner-wrapper {
        height: 140px;
    }
    .Home .team-image-container {
        display: flex;
        flex-direction: column;
    }
    .Home .profile-container {
        width: 100%;
        margin: 1em 0;
    }
    .Home .profile-image {
        width: 75%;
    }
}

