:root {
  --largeSplit: 60%;
  --smallSplit: 40%;
}
.Story .downArrow {
  color: white;
  font-size: 2em;
}
.storyline-item-wrapper {
}
.storyline-item-container {
  margin: 2em 0;
  padding: 1em;
  border: 1.5px #fff solid;
}
.storyline-item-container-odd {
  border-radius: 0px 40px 40px 0px;
  background-image: url("../../assets/bg_fuzzy.png");
  background-size: cover;
}
.storyline-item-container-even {
  border-radius: 40px 0px 0px 40px;
  background-image: url("../../assets/bg_space.png");
  background-size: cover;
}
.storyline-item-container h2 {
  font-family: 'pxlxxl';
  font-weight: normal;
  font-size: 3.5em;
  color: #ffffff;
  margin: 0;
}
.storyline-item {
  padding: 2em 0 1em 0;
}
.storyline-item-first {
  margin: 0em 0 3em 0;
  padding: 0 0 2em 0;
  border-bottom: 1px white dashed;
}
.storyline-item .title {
  width: 100%;
}
.storyline-item-odd,  .storyline-item-even {
  display: flex;
  justify-content: space-evenly;
}
.storyline-item p {
  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  color: #ffffff;
  margin: 0;
}
.storyline-item img {
  object-fit: cover;
  width: 100%;
  height: 220px;
}
.storyline-item .center img {
  object-fit: contain;
  height: 240px;
}
.storyline-item .center {
  margin-top: 1em;
  text-align: center;
  font-size: 1.5em;
}

.storyline-item .right, .storyline-item-container .right {
  text-align: right;
}
.storyline-item .left,  .storyline-item-container .left {
  text-align: left;
}
.storyline-item-odd > .left, .storyline-item-even > .right {
  width: var(--largeSplit);
}
.storyline-item-odd > .right, .storyline-item-even > .left {
  width: var(--smallSplit);
}
.storyline-item-odd > .right {
  padding-left: 2em;

}
.storyline-item-even > .left {
  padding-right: 2em;
}