.timeline-v2 {
  border: 0px solid #ffffff;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
}
.timebox {
  height: 100%;
  width: 25%;
  display: flex;
  align-self: center; 
  justify-content: center; 
}
.timebox > div {
  height: 100%;
  position: inherit;
  display: flex;
  align-self: center;
  justify-content: center;
  
}
.description-box {
  border: 0px solid #ffffff;
  box-sizing: border-box;
  height: 100%;
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 1em;
}
.percent-number {
  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 2em;
  /* text-align: end; */
  color: #ffffff;
  margin-block-start: 0;
  margin-block-end: 0;
}
.percent {
  font-family: JetBrains Mono;

}
.description-text {
  list-style-type: disclosure-closed;
}
.description-text > li{
  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: start;
  padding: 1em;
  color: #ffffff;
}

@media (max-width: 768px) {
  #roadmap .new-section-text {
    padding: 2em 0;
  }
  .timeline-v2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .timebox {
    justify-content: center;
    height: 100%;
    font-size: 0.25em;
  }
  .description-box {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .percent-number  {
    font-family: JetBrains Mono;
    font-size: 32px;
    text-align: center;
    color: #ffffff;
  }

}
